import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import DfCategory from "@/vue/domain/category/df-category";
import DfStore from "@/vue/domain/store/df-store";
import DfContent from "@/vue/domain/content/df-content";
import DfProduct from "@/vue/domain/product/df-product";
import { CONTAINER_TYPES } from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfBrand from "@/vue/domain/brand/df-brand";
import moment from "moment";
const DfSectionBannerComponent = () => Utils.externalComponent2("df-section-banner");
const DfSectionProductsComponent = () => Utils.externalComponent2("df-section-products");
const DfSectionFeauturedComponent = () => Utils.externalComponent2("df-section-feautured");
const DfSectionSpecialComponent = () => Utils.externalComponent2("df-section-special");
const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");
const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");
const DfSectionDigitalPublicationComponent = () => Utils.externalComponent2("df-section-digital-publication");

const lazyLoadingEnabled = process.env.VUE_APP_LAZY_LOADING_ENABLED;

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfFooter: () => Utils.externalComponent2("df-footer"),
    DfSectionProducts: DfSectionProductsComponent,
    DfSectionBanner: DfSectionBannerComponent,
    DfSectionFeautured: DfSectionFeauturedComponent,
    DfSectionSpecial: DfSectionSpecialComponent,
    DfStoreCard: DfStoreCardComponent,
    DfSectionDigitalPublication: DfSectionDigitalPublicationComponent,
    DfLoadingMask: () => import(/* webpackChunkName: 'df-loading-mask' */ "@/vue/components/df-loading-mask/df-loading-mask.vue"),
    DfModal: () => import(/* webpackChunkName: 'df-modal' */ "@/vue/components/df-modal/df-modal.vue"),
  },
})
export default class DfPagePromotionComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Boolean, default: false })
  loading: boolean;

  private showBannersHeader = true;
  private showBannersBanner = true;
  private showFeauturedes = true;
  private showSpecials = true;
  private showBannersFlyer = true;
  private showProductsFlyer = true;
  private lazyLoadingEnabled = true;
  private downloading = false;
  private showPopup = false;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get promotion(): DfPromotion {
    return this.$store.getters.currentPromotion;
  }

  get promotions(): Array<DfPromotion> {
    return this.$store.getters.promotions;
  }

  get banners(): Array<DfContent> {
    return this.$store.getters.promotionContents.filter((content: DfContent) => content.type.code === "BAN");
  }

  get promotionHeader(): string {
    return Utils.getPropertyImageUrl(this.promotion, Utils.PROPERTY_HEADER, null);
  }

  get selectedTags(): Array<DfCategory> {
    return this.$store.getters.activeTags;
  }

  get selectedCategory(): Array<DfCategory> {
    return this.$store.getters.activeCategory;
  }

  get productsQueryValue(): string {
    return this.$store.getters.productsQueryValue;
  }

  get isBannersFlyerVisible(): boolean {
    return this.showBannersFlyer && this.isSearchEmpty && this.storeDefaultViewMode !== Utils.PRODUCTS_FLYER_VIEW;
  }

  get isProductsFlyerVisible(): boolean {
    return this.showProductsFlyer && this.isSearchEmpty;
  }

  get isBannersHeaderVisible(): boolean {
    return this.showBannersHeader && this.isSearchEmpty;
  }

  get isBannersBannerVisible(): boolean {
    return this.showBannersBanner && this.isSearchEmpty;
  }

  get isFeauturedesVisible(): boolean {
    return this.showFeauturedes && this.isSearchEmpty;
  }

  get isSpecialsVisible(): boolean {
    return this.showSpecials && this.isSearchEmpty;
  }

  get customFiltersQuery(): Array<string> {
    return this.$store.getters.customFiltersQuery;
  }

  get isSearchEmpty(): boolean {
    const rule01: boolean = this.selectedTags.length === 0;
    const rule02: boolean = this.productsQueryValue ? this.productsQueryValue.length === 0 : true;
    const rule03: boolean = this.selectedCategory == null;
    const rule04: boolean = this.customFiltersQuery.length === 0;
    return rule01 && rule02 && rule03 && rule04;
  }

  get showNextPageButton(): boolean {
    const totalElements: number = this.$store.getters.productsPaging ? this.$store.getters.productsPaging.totalElements : 0;
    return this.$store.getters.products.length < totalElements;
  }

  get products(): Array<DfProduct> {
    return this.$store.getters.products;
  }

  get promotionsLoaded(): boolean {
    return this.$store.getters.promotionsLoaded;
  }

  get storeDefaultViewMode(): string {
    const valueList: any = Utils.getPropertyValue(this.currentStore, Utils.PROPERTY_PRODUCTS_VIEW_MODE, "LIST", { key: Utils.PRODUCTS_ALL_VIEWS });
    return valueList.key;
  }

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get showAllViews(): boolean {
    const defaultViewMode: string = this.promotionDefaultViewMode || this.brandDefaultViewMode || this.storeDefaultViewMode;
    return defaultViewMode === Utils.PRODUCTS_ALL_VIEWS;
  }

  get promotionDefaultViewMode(): string {
    const valueList: any = Utils.getPropertyValue(this.promotion, Utils.PROPERTY_PRODUCTS_DEFAULT_VIEW_MODE, "LIST");
    return valueList ? valueList.key : null;
  }

  get brandDefaultViewMode(): string {
    const valueList: any = Utils.getPropertyValue(this.brand, Utils.PROPERTY_PRODUCTS_DEFAULT_VIEW_MODE, "LIST");
    return valueList ? valueList.key : null;
  }

  get productsSwitchActive(): boolean {
    return this.$store.getters.productsSwitchActive;
  }

  set productsSwitchActive(productsSwitchActive: boolean) {
    this.$store.dispatch("setProductsSwitchActive", productsSwitchActive);
  }

  get flyerSwitchActive(): boolean {
    return this.$store.getters.flyerSwitchActive;
  }

  set flyerSwitchActive(flyerSwitchActive: boolean) {
    this.$store.dispatch("setFlyerSwitchActive", flyerSwitchActive);
  }

  get popups(): Array<DfContent> {
    return this.$store.getters.promotionContents.filter((content: DfContent) => content.type.code === "POP");
  }

  get popupImages(): Array<string> {
    const popup: DfContent = this.popups.length ? this.popups[0] : null;
    return popup ? Utils.getPropertyImagesUrl(popup, Utils.PROPERTY_PROMOTION_POPUP_IMAGE) : [];
  }

  get popupStartDate(): string {
    const popup: DfContent = this.popups.length ? this.popups[0] : null;
    return popup ? Utils.getPropertyValue(popup, Utils.PROPERTY_PROMOTION_POPUP_START_DATE, "DATE") : null;
  }

  get popupEndDate(): string {
    const popup: DfContent = this.popups.length ? this.popups[0] : null;
    return popup ? Utils.getPropertyValue(popup, Utils.PROPERTY_PROMOTION_POPUP_END_DATE, "DATE") : null;
  }

  get popupInternalUrl(): string {
    const popup: DfContent = this.popups.length ? this.popups[0] : null;
    const url: string = popup ? Utils.getPropertyValue(popup, Utils.PROPERTY_PROMOTION_POPUP_URL, "STRING") : null;
    return Utils.isExternalLink(url) ? null : url;
  }

  get popupExternalUrl(): string {
    const popup: DfContent = this.popups.length ? this.popups[0] : null;
    const url: string = popup ? Utils.getPropertyValue(popup, Utils.PROPERTY_PROMOTION_POPUP_URL, "STRING") : null;
    return Utils.isExternalLink(url) ? url : null;
  }

  get popupShowAlways(): boolean {
    const popup: DfContent = this.popups.length ? this.popups[0] : null;
    return Utils.getPropertyValue(popup, Utils.PROPERTY_PROMOTION_POPUP_SHOW_ALWAYS, "BOOLEAN", false);
  }

  get promotionsPopupsWhatched(): Array<string> {
    return this.$store.getters.promotionsPopupsWhatched;
  }

  set promotionsPopupsWhatched(promotionsPopupsWhatched: Array<string>) {
    this.$store.dispatch("setPromotionsPopupsWhatched", promotionsPopupsWhatched);
  }

  get pdfData(): any {
    const digitalPublication: DfContent = this.digitalPublications.find((digitalPublication: DfContent) => {
      const value: any = Utils.getPropertyValue(digitalPublication, Utils.PROPERTY_POSITION, "LIST");
      return value ? value.key === "ALL" || value.key === "PRODUCTS_LIST" : false;
    });
    return digitalPublication ? Utils.getPropertyValue(digitalPublication, Utils.PROPERTY_PDF, "FILE") : null;
  }

  @Watch("promotionDefaultViewMode", { immediate: true })
  @Watch("brandDefaultViewMode", { immediate: true })
  @Watch("storeDefaultViewMode", { immediate: true })
  private productsViewModeChange() {
    this.setDefaultView();
  }

  @Watch("popupImages", { immediate: true })
  private popupImagesChanged() {
    if (this.popupImages.length) {
      const today: string = moment(Date.now()).format(Utils.ISO_8601_STRING_PATTERN)
      const rule01 = this.popupImages.length > 0;
      const rule02 = !this.popupStartDate || today >= this.popupStartDate;
      const rule03 = !this.popupEndDate || today <= this.popupEndDate;
      const rule04 = !this.promotionsPopupsWhatched.includes(`${this.promotion.code}_${this.popupImages[0]}`)
      const rule05 = this.$route.query.showbanner || this.popupShowAlways;

      if (rule01 && rule02 && rule03 && rule04 && rule05) {
        this.showPopup = true;
        this.promotionsPopupsWhatched = [...this.promotionsPopupsWhatched, `${this.promotion.code}_${this.popupImages[0]}`];
      }
    }
  }

  @Watch("showPopup", { immediate: true })
  private showPopupChange() {
    this.killIubenda(this.showPopup);
  }

  beforeMount() {
    this.lazyLoadingEnabled = lazyLoadingEnabled === "true";
  }

  mounted() {
    document.addEventListener("scroll", this.infiniteLoading);
  }

  beforeDestroy() {
    document.removeEventListener("scroll", this.infiniteLoading);
  }

  private killIubenda(kill: boolean) {
    Utils.killIubenda(kill);
  }
  private setBannersHeaderVisibility(visible: boolean) {
    this.showBannersHeader = visible;
  }

  private setBannersBannerVisibility(visible: boolean) {
    this.showBannersBanner = visible;
  }

  private setBannersFlyerVisibility(visible: boolean) {
    this.showBannersFlyer = visible;
  }

  private setProductsFlyerVisibility(visible: boolean) {
    this.showProductsFlyer = visible;
  }

  private setFeauturedesVisibility(visible: boolean) {
    this.showFeauturedes = visible;
  }

  private setSpecialsVisibility(visible: boolean) {
    this.showSpecials = visible;
  }

  get promotionContents(): Array<DfContent> {
    return this.$store.getters.promotionContents;
  }

  get digitalPublications(): Array<DfContent> {
    return this.promotionContents.filter((content: DfContent) => content.type.code === "FLY");
  }

  get productsFlyerSwitchIsActive(): boolean {
    return this.digitalPublicationByPositionCount("PRODUCTS_LIST") > 0;
  }

  private setProductView() {
    this.productsSwitchActive = true;
    this.flyerSwitchActive = false;
  }

  private setFlyerView() {
    this.productsSwitchActive = false;
    this.flyerSwitchActive = true;
  }

  private setDefaultView() {
    const defaultViewMode: string = this.promotionDefaultViewMode || this.brandDefaultViewMode || this.storeDefaultViewMode;
    if (defaultViewMode === Utils.PRODUCTS_FLYER_VIEW) {
      this.setFlyerView();
    } else {
      this.setProductView();
    }
  }

  private digitalPublicationByPositionCount(position: string): number {
    return this.digitalPublications.filter((digitalPublication: DfContent) => {
      const value: any = Utils.getPropertyValue(digitalPublication, Utils.PROPERTY_POSITION, "LIST");
      const url: boolean = Utils.getPropertyValues(digitalPublication, Utils.PROPERTY_PDF, "FILE").length > 0;

      return value && url ? value.key === "ALL" || value.key === position : false;
    }).length;
  }

  private infiniteLoading() {
    if (Math.ceil(document.scrollingElement.scrollTop) + window.innerHeight + 300 >= document.body.scrollHeight) {
      this.$root.$emit("loadNextProductsPage");
    }
  }

  private loadNextProductsPageManual() {
    this.$root.$emit("loadNextProductsPageManual");
  }

  private goToStorePage(store: DfStore) {
    this.$router.push({ name: "store", params: { storeAlias: store.alias } }).catch((err) => err);
  }

  private openMap() {
    window.open(`${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(this.currentStore)}`, "_blank");
  }

  private downloadFlyer() {
    const digitalPublication: DfContent = this.digitalPublications.find((digitalPublication: DfContent) => {
      const value: any = Utils.getPropertyValue(digitalPublication, Utils.PROPERTY_POSITION, "LIST");
      return value ? value.key === "ALL" || value.key === "PRODUCTS_LIST" : false;
    });
    const pdfData: any = digitalPublication ? Utils.getPropertyValue(digitalPublication, Utils.PROPERTY_PDF, "FILE") : null;

    if (pdfData) {
      this.downloading = true;
      this.service
        .downloadFile({ fileUniqueId: pdfData.uniqueId, fileName: pdfData.name })
        .then((file: any) => {
          this.downloading = false;
          Utils.downloadFile(file, `${pdfData.basename}.${pdfData.extension}`, Utils.getMimeFromExtension(pdfData.extension));
        })
        .catch((error: Error) => {
          this.downloading = false;
          Utils.defaultApiErrorHandler(error, this);
        });
    }
  }

  private fullscreenFlyer() {
    this.$root.$emit("modules-modal.open", "df-modal.id.pdf-fullscreen");
    Utils.killIubenda(true);
  }

  @Watch("promotion", { immediate: true })
  promotionChange() {
    document.title = this.promotion ? `${this.promotion.description} | ${Utils.APP_NAME}` : "";
    if (!this.promotion && this.promotionsLoaded) {
      this.$router.push({ name: "home-store", params: { storeAlias: this.currentStore.alias } });
    }
  }

  @Watch("isSearchEmpty")
  isSearchEmptyChange() {
    if (this.isSearchEmpty) {
      this.setDefaultView()
    } else {
      this.setProductView();
    }
  }
}
